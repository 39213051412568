<template>
  <div class="main-content">
    <div v-if="isLoading" class="loading_page spinner spinner-primary mr-3"></div>
    <b-card class="wrapper" v-if="!isLoading">
      <vue-good-table mode="remote" :columns="columns" :totalRows="totalRows" :rows="attendances"
        @on-page-change="onPageChange" @on-per-page-change="onPerPageChange" @on-sort-change="onSortChange"
        @on-search="onSearch" :search-options="{
          enabled: false,
          placeholder: $t('Search this table'),
        }" :select-options="{
  enabled: false,
  clearSelectionText: '',
}" @on-selected-rows-change="selectionChanged" :pagination-options="{
  enabled: false,
  mode: 'records',
  nextLabel: 'next',
  prevLabel: 'prev',
}" styleClass="vgt-table bordered striped table-hover">
        <div slot="selected-row-actions">
          <button class="btn btn-danger btn-sm" @click="delete_by_selected()">{{ $t('Del') }}</button>
        </div>
        <div slot="table-actions">
          <b-button @click="New_attendance()" class="btn-rounded" variant="btn btn-primary btn-icon mr-1">
            {{ $t('Add') }}
          </b-button>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <a @click="Edit_Attendance(props.row)" class="cursor-pointer" title="Edit" v-b-tooltip.hover>
              <img :src="$helpers.getIcons('edit')" alt="" class="cursor-pointer action-icon-grid">
            </a>
            <a title="Delete" v-b-tooltip.hover class="cursor-pointer" @click="Remove_Attendance(props.row.id)">
              <img :src="$helpers.getIcons('delete')" alt="" class="cursor-pointer action-icon-grid">
            </a>
          </span>
        </template>
      </vue-good-table>
    </b-card>

    <validation-observer ref="Create_Attendance">
      <b-modal hide-footer size="md" id="Modal_attendance" :title="editmode ? $t('Edit') : $t('Add')">
        <b-form @submit.prevent="Submit_Attendance">
          <b-row>
            <!-- Employee -->
            <b-col md="12">
              <validation-provider name="Employee" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Employee') + ' ' + '*'">
                  <v-select :class="{ 'is-invalid': !!errors.length }" :state="errors[0] ? false : (valid ? true : null)"
                    v-model="attendance.employee_id" class="required" required @input="Selected_Employee"
                    :placeholder="$t('Choose Employee')" :reduce="label => label.value"
                    :options="employees.map(employees => ({ label: employees.username, value: employees.id }))" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Date  -->
            <b-col md="12">
              <validation-provider name="Date" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Date') + ' ' + '*'">
                  <Datepicker id="date" name="date" :placeholder="$t('Enter Attendance date')" v-model="attendance.date"
                    input-class="form-control back_important" format="yyyy-MM-dd"
                    @closed="attendance.date = formatDate(attendance.date)">
                  </Datepicker>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Time_In  -->
            <b-col md="6">
              <validation-provider name="Time_In" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Time In') + ' ' + '*'">
                  <b-form-timepicker v-model="attendance.clock_in" locale="en" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Time_In  -->
            <b-col md="6">
              <validation-provider name="Time Out" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Time Out') + ' ' + '*'">
                  <b-form-timepicker v-model="attendance.clock_out" locale="en" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12" class="mt-3">
              <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i
                  class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
              <div v-once class="typo__p" v-if="SubmitProcessing">
                <div class="spinner sm spinner-primary mt-3"></div>
              </div>
            </b-col>

          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import VueClockPicker from '@pencilpix/vue2-clock-picker';
import Datepicker from 'vuejs-datepicker';
import { VueGoodTable } from 'vue-good-table'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'

export default {
  metaInfo: {
    title: "Attendance"
  },

  data() {
    return {
      isLoading: true,
      SubmitProcessing: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      editmode: false,
      employees: [],
      attendances: [],
      attendance: {
        employee_id: "",
        date: "",
        clock_in: "",
        clock_out: "",
      },

      office_shift: {
        name: "",
        monday_in: "",
        monday_out: "",
        tuesday_in: "",
        tuesday_out: "",
        wednesday_in: "",
        wednesday_out: "",
        thursday_in: "",
        thursday_out: "",
        friday_in: "",
        friday_out: "",
        saturday_in: "",
        saturday_out: "",
        sunday_in: "",
        sunday_out: "",
      },
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("Employee"),
          field: "employee_username",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("date"),
          field: "date",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Time In"),
          field: "clock_in",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Time Out"),
          field: "clock_out",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Work Duration"),
          field: "total_work",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Action"),
          field: "actions",
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false
        }
      ];
    }
  },
  components: {
    VueClockPicker,
    Datepicker,
    VueGoodTable
  },
  methods: {
    //---- update Params Table
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    //---- Event Page Change
    onPageChange({ currentPage }) {
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.Get_Attendances(currentPage);
      }
    },

    //---- Event Per Page Change
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        this.Get_Attendances(1);
      }
    },

    //---- Event Select Rows
    selectionChanged({ selectedRows }) {
      this.selectedIds = [];
      selectedRows.forEach((row, index) => {
        this.selectedIds.push(row.id);
      });
    },

    formatDate(d) {
      var m1 = d.getMonth() + 1;
      var m2 = m1 < 10 ? '0' + m1 : m1;
      var d1 = d.getDate();
      var d2 = d1 < 10 ? '0' + d1 : d1;
      return [d.getFullYear(), m2, d2].join('-');
    },

    //---- Event Sort Change

    onSortChange(params) {
      let field = "";
      if (params[0].field == "employee_username") {
        field = "employee_id";
      } else {
        field = params[0].field;
      }
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.Get_Attendances(this.serverParams.page);
    },

    //---- Event Search
    onSearch(value) {
      this.search = value.searchTerm;
      this.Get_Attendances(this.serverParams.page);
    },

    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    //------------- Submit Validation Create & Edit attendance
    Submit_Attendance() {
      this.$refs.Create_Attendance.validate().then(success => {
        if (!success) {
          showErrorNotification(this, "Please fill the form correctly")
        } else {
          if (!this.editmode) {
            this.Create_Attendance();
          } else {
            this.Update_Attendance();
          }
        }
      });
    },

    //------------------------------ Show Modal (Create attendance) -------------------------------\\
    New_attendance() {
      this.reset_Form();
      this.editmode = false;
      this.$bvModal.show("Modal_attendance");
    },

    //------------------------------ Show Modal (Update attendance) -------------------------------\\
    Edit_Attendance(attendance) {
      this.editmode = true;
      this.reset_Form();
      this.Get_employees_by_company();
      this.attendance = attendance;
      this.$bvModal.show("Modal_attendance");
    },

    Selected_Company(value) {
      this.employees = [];
      this.attendance.employee_id = "";
      this.Get_employees_by_company();
    },

    Selected_Employee(value) {
      if (value === null) {
        this.attendance.employee_id = "";
      }
    },

    //---------------------- Get_employees_by_company ------------------------------\\

    Get_employees_by_company() {
      axios
        .get(apiUrl+"/core/get_employees_by_company")
        .then(({ data }) => (this.employees = data));
    },



    //--------------------------Get ALL attendances ---------------------------\\

    Get_Attendances(page) {
      // Start the progress bar.
      axios
        .get(
          apiUrl+"/attendances?page=" +
          page +
          "&SortField=" +
          this.serverParams.sort.field +
          "&SortType=" +
          this.serverParams.sort.type +
          "&search=" +
          this.search +
          "&limit=" +
          this.limit
        )
        .then(response => {
          this.totalRows = response.data.totalRows;
          this.attendances = response.data.attendances;

          // Complete the animation of theprogress bar.
          this.isLoading = false;
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    //------------------------------- Create attendance ------------------------\\
    Create_Attendance() {

      this.SubmitProcessing = true;
      axios
        .post(apiUrl+"/attendances", {
          employee_id: this.attendance.employee_id,
          date: this.attendance.date,
          clock_in: this.attendance.clock_in,
          clock_out: this.attendance.clock_out,

        })
        .then(response => {
          this.SubmitProcessing = false;
          Fire.$emit("Event_Attendance");
          showSuccessNotification(this, 'Created successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- Update attendance ------------------------\\
    Update_Attendance() {
      this.SubmitProcessing = true;
      axios
        .put(apiUrl+"/attendances/" + this.attendance.id, {
          employee_id: this.attendance.employee_id,
          date: this.attendance.date,
          clock_in: this.attendance.clock_in,
          clock_out: this.attendance.clock_out,
        })
        .then(response => {
          this.SubmitProcessing = false;
          Fire.$emit("Event_Attendance");
          showSuccessNotification(this, 'Updated successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
      this.attendance = {
        employee_id: "",
        date: "",
        clock_in: "",
        clock_out: "",
      };
    },

    //------------------------------- Delete attendance ------------------------\\
    Remove_Attendance(id) {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(apiUrl+"/attendances/" + id)
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Attendance");
            })
            .catch(() => {
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    },

    //---- Delete attendance by selection

    delete_by_selected() {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Start the progress bar.
          axios
            .post(apiUrl+"/attendances/delete/by_selection", {
              selectedIds: this.selectedIds
            })
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Attendance");
            })
            .catch(() => {
              // Complete the animation of theprogress bar.
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    }
  },

  //----------------------------- Created function-------------------\\

  created: function () {
    this.Get_Attendances(1);
    this.Get_employees_by_company();
    Fire.$on("Event_Attendance", () => {
      setTimeout(() => {
        this.Get_Attendances(this.serverParams.page);
        this.$bvModal.hide("Modal_attendance");
      }, 500);
    });

    Fire.$on("Delete_Attendance", () => {
      setTimeout(() => {
        this.Get_Attendances(this.serverParams.page);
      }, 500);
    });
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
